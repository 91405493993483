import ProductActionTypes from "./actions-types";

const initialState = {
    currentProduct: []
}

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ProductActionTypes.ADDPRODUCT:
            if (action.payload.quantity > 0) {
                const filter = { ...state, currentProduct: state.currentProduct.filter((product) => product.id !== action.payload.id) }

                state = filter
                return { currentProduct: [...state.currentProduct, action.payload] };
            } else {
                const filter = { ...state, currentProduct: state.currentProduct.filter((product) => product.id !== action.payload.id) }

                state = filter

                return { currentProduct: [...state.currentProduct] }
            }
        case ProductActionTypes.REMOVEPRODUCT:
            return { ...state, currentProduct: action.payload };
        case ProductActionTypes.CLEANPRODUCT:
            return { currentProduct: [] }
        default:
            return state;
    }
};

export default productReducer;