import { combineReducers } from "redux";
import accordionReducer from "./accordion/reducer";
import productReducer from "./product/reducer";
import configurationReducer from "./configurationTheme/reducer";
import nuvemFiscalReducer from "./nuvemFiscal/reducer";
import userReducer from "./user/reducer";
import cupomReducer from "./cupom/reducer";

const rootReducer = combineReducers({ productReducer, configurationReducer, userReducer, nuvemFiscalReducer, cupomReducer });

export default rootReducer;