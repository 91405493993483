import CupomActionTypes from "./actions-types"

export const addCupom = (payload) => ({
    type: CupomActionTypes.ADDCUPOM,
    payload
})

export const removeCupom = (payload) => ({
    type: CupomActionTypes.REMOVECUPOM,
    payload
})

export const cleanCupom = (payload) => ({
    type: CupomActionTypes.CLEANCUPOM,
})
