import React from 'react'
import { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './pages/Header'
import Home from './pages/Home'
import Payment from './pages/Payment'
import Footer from './pages/Footer'
import './default.css'
import PageNotFound from './pages/PageNotFound';
import api from "./service/api";
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive'

function Rotas() {
    const [accordion, setAccordion] = useState(true);
    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const value = { accordion, setAccordion }
    let storage = '';
    return (
        <Router>
            <div className='BaseContainer'>
                <div className='CenterContainer'>
                    <Header />
                    <Routes>
                        <Route exact element={<Home />} path='/:db' />
                        <Route exact element={<Payment />} path='/fechamento/:db' />
                        <Route exact element={<PageNotFound />} path='*' />
                    </Routes>
                    <Footer />
                </div>
            </div>
        </Router>
    )
}

export default Rotas