import nuvemFiscalActionType from "./actions-types"

export const activeModal = (payload) => ({
    type: nuvemFiscalActionType.ACTIVEMODAL,
    payload
})

export const endModal = (payload) => ({
    type: nuvemFiscalActionType.ACTIVEMODAL,
    payload
})

export const hourModal = (payload) => ({
    type: nuvemFiscalActionType.ACTIVEMODAL,
    payload
})