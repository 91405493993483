import React from 'react'
import './index.css'
import Routes from './Routes'
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from 'react-helmet'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import api from "./service/api";
import { configurationTheme } from './redux/configurationTheme/actions'
import { useSelector, useDispatch } from 'react-redux';
import "typeface-roboto"


const container = document.getElementById('root');
const root = createRoot(container);

var db = window.location.pathname;

document.addEventListener("DOMContentLoaded", function (event) {
  api
    .get('/TodosUsuariosRevenda')
    .then(response => {
      var dbSplit = db.split('/')
      response.data.map(key => {
        if (key.db_name == dbSplit[1]) {
          if (key.type_reseller == 'Ultragaz') {
            document.body.style.setProperty('--primaryColor', '#000fff');
            document.body.style.setProperty('--secundaryColor', '#00ff00');
          } else if (key.type_reseller == 'Liquigás') {
            document.body.style.setProperty('--primaryColor', '#02714e');
            document.body.style.setProperty('--secundaryColor', '#f48835');
          } else if (key.type_reseller == 'Copagaz') {
            document.body.style.setProperty('--primaryColor', '#02714e');
            document.body.style.setProperty('--secundaryColor', '#f48835');
          } else if (key.type_reseller == 'Supergasbras') {
            document.body.style.setProperty('--primaryColor', '#f62d01');
            document.body.style.setProperty('--secundaryColor', '#ffbe00');
          } else if (key.type_reseller == 'Nacional Gás') {
            document.body.style.setProperty('--primaryColor', '#eb4034');
            document.body.style.setProperty('--secundaryColor', '#dadada');
          } else if (key.type_reseller == 'Consigaz') {
            document.body.style.setProperty('--primaryColor', '#153170');
            document.body.style.setProperty('--secundaryColor', '#da251c');
          } else if (key.type_reseller == 'NGC') {
            document.body.style.setProperty('--primaryColor', '#eb4034');
            document.body.style.setProperty('--secundaryColor', '#dadada');
          } else if (key.type_reseller == 'Servigás') {
            document.body.style.setProperty('--primaryColor', '#4da291');
            document.body.style.setProperty('--secundaryColor', '#ee8e59');
          }
        }
      })
    })
});

root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Helmet>
        <title>Clube do revendedor de gás - Whatsapp</title>
        <meta name="description" content="Whatsapp" />
        <meta property="og:title" content="Whatsapp" />
        <meta property="og:description" content="Whatsapp | Clube do revendedor de gás" />

        <meta property="og:image" content="https://painel.atlantisagencia.com.br/api/static/media/Prancheta_7.b38765c7e45aa758b8f0.png" />

      </Helmet>
      <Routes />
    </PersistGate>
  </Provider>
)
