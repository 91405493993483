import ConfigurationActionType from "./actions-types";

const initialState = {
    currentTheme: null
}

const configurationReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConfigurationActionType.CONFIGURATION:
            return { ...state, currentTheme: action.payload };
        default:
            return state;
    }
};

export default configurationReducer;