import { useEffect, useState } from 'react';
import React from 'react'
import { Navbar, Container, Form } from 'react-bootstrap';
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { cleanProduct } from '../../redux/product/actions';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment'
import Box from '@mui/material/Box';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { activeModal } from '../../redux/nuvemFiscal/actions';
import axios from 'axios';

const Footer = () => {
    const [to, setTo] = useState('/fechamento/' + db);
    const [title, setTitle] = useState('Finalizar');
    const [products, setProducts] = useState([]);
    const [productName, setProductName] = useState([]);
    const [total, setTotal] = useState(0);
    const [saleLocation, setSaleLocation] = useState(1);
    const [payment, setPayment] = useState([])
    const [show, setShow] = useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [valueCupom, setValueCupom] = useState(0)
    const [color, setColor] = useState('#26398C');
    const [drivers, setDrivers] = useState([])
    const [productId, setProductId] = useState([])

    const { currentCupom } = useSelector(rootReducer => rootReducer.cupomReducer);

    let db = window.location.pathname;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const location = useLocation();
    const { hash, pathname, search } = location;
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { currentProduct } = useSelector(rootReducer => rootReducer.productReducer);
    const today = new Date;
    var dbSplit = db.split('/');
    if (dbSplit.length > 2) {
        db = dbSplit[2];
    } else {
        db = dbSplit[1];
    }

    useEffect(() => {
        dispatch(cleanProduct())
    }, [])

    useEffect(() => {
        api.get('/TodosCanaisVendas/' + db)
            .then(response => {
                response.data.map(key => {
                    if (key.name == 'Whatsapp') {
                        setSaleLocation(key.id)
                    }
                })
            })
    }, [])

    useEffect(() => {
        api.get('/TodosMotoristas/' + db)
            .then(response => {
                setDrivers(response.data)
            })
    }, [])

    useEffect(() => {
        api.get('/TodasFormasPagamentos/' + db)
            .then(response => {
                setPayment(response.data);
            })
    }, [])

    useEffect(() => {
        api.get('/TodosProdutos/' + db)
            .then(response => {
                setProducts(response.data);
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        let sum = 0;
        var arr = []
        setPayment([])
        products.map(product => {
            currentProduct.map(key => {
                if (product.id == key.id) {
                    let valueFormated = parseFloat(product.value.replace('R$', '').replace('.', '').replace(',', '.'))
                    let valuePromoFormated = null

                    if (product.promotional_value !== null && product.promotional_value !== '') {
                        valuePromoFormated = parseFloat(product.promotional_value.replace('R$', '').replace('.', '').replace(',', '.'))
                    }

                    let value = valuePromoFormated == null ? valueFormated : valuePromoFormated
                    sum += (parseFloat(value) * Number(key.quantity))
                    productName.push(key.quantity + 'x ' + product.name);
                    arr.push(key.id)
                }
            })
        })
        var novaArr = arr.filter((este, i) => arr.indexOf(este) === i)
        setProductId(novaArr)
        setTotal(sum)
    }, [currentProduct])

    useEffect(() => {
        let cupom = parseFloat(currentCupom.value);
        if (isNaN(cupom)) {
            cupom = 0;
        }
        if (cupom !== 0) {
            setValueCupom(Number(cupom))
        } else {
            setValueCupom(0)
        }
    }, [currentCupom, total])

    const handleClick = async () => {
        if (total > 0) {
            if (pathname == "/" + db) {
                navigate('/fechamento/' + db);
                setTitle('Concluir')
            } else {
                let name = document.getElementById('namePayment').value;
                let whatsapp = document.getElementById('whatsappPayment').value;
                let cep = document.getElementById('cepPayment').value;
                let address = document.getElementById('addressPayment').value;
                let number = document.getElementById('numberPayment').value;
                let bairro = document.getElementById('bairroPayment').value;
                let reference = document.getElementById('referencePayment').value;
                let radio = document.getElementById('radioPayment').value;
                let idClient = document.getElementById('idClientPayment').value;
                let id = '';
                let driver_id = null;
                let status = 'Recebido'

                let paymentName = ''
                var novaArr = productName.filter((este, i) => productName.indexOf(este) === i);
                var ids = productId.join();
                payment.map(key => {
                    if (key.id == radio) {
                        paymentName = key.name;
                    }
                })
                let whatsappFormated = whatsapp.replace(/[^\d]/g, '');

                if (drivers.length == 1) {
                    driver_id = drivers[0].id
                    status = 'Entregador notificado'
                }

                if (name !== '' && whatsapp !== '' && address !== '' && number !== '' && bairro !== '') {
                    if (Number(idClient > 0)) {
                        id = Number(idClient);
                    } else {
                        await api.post('CadastrarClienteFisico', {
                            name: name,
                            number: number,
                            street: address,
                            neighborhood: bairro,
                            reference_point: reference,
                            phone: whatsappFormated,
                            cep: cep,
                            db: db
                        })
                            .then(response => {
                                id = Number(response.data.id)
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }

                    if (id !== '' || id !== 0) {
                        await api
                            .post('/CadastrarPedido', {
                                client_id: id,
                                sale_channel: saleLocation,
                                product_id: ids,
                                products: novaArr.toString(),
                                payment_id: Number(radio),
                                total: (total - valueCupom),
                                order_date: moment(today).format('DD/MM/YYYY hh:mm:ss'),
                                typeClient: 'fisico',
                                quick_client: `${name}/${address},  ${number} - ${bairro} - ${reference}`,
                                driver_id: driver_id,
                                status: status,
                                db: db
                            })
                            .then(response => {
                                localStorage.setItem("nameClient", name);
                                localStorage.setItem("whatsapp", whatsapp);
                                localStorage.setItem("cep", cep);
                                localStorage.setItem("address", address);
                                localStorage.setItem("number", number);
                                localStorage.setItem("bairro", bairro);
                                localStorage.setItem("reference", reference);
                                
                                let message = `Olá ${name}, o seu pedido foi feito com sucesso! \n \n Pedido nº ${response.data.id} \n \n Itens: ➡ ⁠ ${novaArr.toString()} \n \n 💳 ${paymentName} \n \n 🏪 Entrega em: \n ${address},  ${number} - ${bairro} - ${reference} \n \n Total: R$ ${(total - valueCupom)} \n \n Obrigado pela preferência, se precisar de algo é só chamar! 😉`;

                                axios
                                    .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${db}`,
                                        {
                                            number: "55" + whatsappFormated + "@s.whatsapp.net",
                                            textMessage: {
                                                text: message
                                            },
                                            delay: 1200
                                        }, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                                        }
                                    })
                                    .then((response) => {
                                        // console.log(response);
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })

                                setProductName([])
                                navigate('/' + db);
                                setTitle('Finalizar')
                                dispatch(cleanProduct())
                                dispatch(activeModal({ modal: 'end', status: true }))
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    }
                }



            }
        } else {
            dispatch(activeModal({ modal: 'alerta', status: true }))
        }

    }

    return (
        <>
            <Navbar expand="lg" className="navBar footer">
                <Container style={{ justifyContent: 'space-between', width: "40%" }}>
                    <div className='divFooter'>
                        <span style={{ color: 'white', fontSize: 25, fontWeight: 400 }}>TOTAL: </span>
                        <CurrencyInput
                            prefix="R$"
                            className='valueOrderedValues'
                            value={(total - valueCupom)}
                            decimalsLimit={2}
                            decimalScale={2}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled
                        />
                    </div>
                    <button className='btnFinalizar' onClick={handleClick}>{title}</button>
                </Container>
            </Navbar>
        </>
    )
}

export default Footer