import ProductActionTypes from "./actions-types"

export const addProduct = (payload) => ({
    type: ProductActionTypes.ADDPRODUCT,
    payload
})

export const removeProduct = (payload) => ({
    type: ProductActionTypes.REMOVEPRODUCT,
    payload
})

export const cleanProduct = (payload) => ({
    type: ProductActionTypes.CLEANPRODUCT,
})
