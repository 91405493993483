import nuvemFiscalActionType from "./actions-types";

const initialState = {
    active: {modal: null, status: false}
}

const nuvemFiscalReducer = (state = initialState, action) => {
    switch (action.type) {
        case nuvemFiscalActionType.ACTIVEMODAL:
            return { active: action.payload };
        case nuvemFiscalActionType.ENDMODAL:
            return { active: action.payload };
        case nuvemFiscalActionType.HOURMODAL:
            return { active: action.payload };
        default:
            return state;
    }
};

export default nuvemFiscalReducer;