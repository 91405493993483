import UserActionTypes from "./actions-types";

const initialState = {
    currentUser: { name: null, whatsapp: null, address: null, number: null, bairro: null, reference: null, payment_id: null }
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.USERDATA:
            return { ...state, currentUser: action.payload };
        default:
            return state;
    }
};

export default userReducer;